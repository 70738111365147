<template>
  <div
    :class="['cards', `cards-layout-${layout}`, `cards-${itemsPerRow}-per-row`]"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      layout: {
        type: String,
        default: "grid",
      },
      itemsPerRow: {
        type: Number,
        default: 3,
        validator: (value) => {
          return [2, 3, 6].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cards {
    display: grid;
    grid-gap: $gutter;

    &.cards-layout-grid {
      &.cards-2-per-row {
        grid-template-columns: repeat(
          auto-fill,
          minmax($base-spacing * 40, 1fr)
        );
      }
      &.cards-3-per-row {
        grid-template-columns: repeat(
          auto-fill,
          minmax($base-spacing * 80, 1fr)
        );
        @include viewport("mini", "handheld") {
          grid-template-columns: repeat(
            auto-fill,
            minmax($base-spacing * 60, 1fr)
          );
        }
      }
      &.cards-6-per-row {
        grid-template-columns: repeat(
          auto-fill,
          minmax($base-spacing * 45, 1fr)
        );
      }
    }
    &.cards-layout-list {
      grid-template-columns: 1fr;
      @include viewport("lg") {
        grid-template-columns: repeat(
          auto-fill,
          minmax($base-spacing * 120, 1fr)
        );
      }
    }
  }
</style>
